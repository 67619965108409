pdfjs-viewer-element {
  width: 100%;
  height: 100%;
}

.pdf-viewer-wrapper {
  width: 100%;
  height: 80vh;
  min-height: 400px;
  max-height: min(80vh, 1400px);
}

@supports (aspect-ratio: auto) {
  .pdf-viewer-wrapper {
    height: unset;
    aspect-ratio: 1 / 1.4143145161;
  }
}

